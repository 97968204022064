import React, {useState, useMemo, useEffect} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Checkbox,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    Input,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    TableSortLabel,
    TablePagination,
    Tooltip,
    IconButton,
    FormControlLabel,
    Switch,
    InputLabel,
    Select,
    MenuItem,
    TextareaAutosize,
    Typography,
} from '@material-ui/core';
import { Icon } from '@vacasa/react-components-lib';
import {
    useGetAllDateListsQuery,
    useAddDateListMutation,
    useUpdateDateListMutation,
    useLinkDatesToParentMutation,
    useGetDateListMutation,
    useRefreshDateListMutation,
} from '../../store';
import { DateList, CurrentUser } from "../../types";
import { format } from 'date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

type SortDirection = 'asc' | 'desc';

interface DateListsProps {
    initialSelectedListId?: number | null;
    currentUser: CurrentUser;
    onClearSelection?: () => void;
}

const formatDate = (date: string | null, forPicker: boolean = false) => {
    if (!date) return forPicker ? null : '-';
    
    // For display, just format the date string
    if (!forPicker) {
        const [year, month, day] = date.split('T')[0].split('-');
        return format(new Date(Number(year), Number(month) - 1, Number(day)), 'yyyy-MM-dd');
    }
    // For date picker, create a date object that won't shift due to timezone
    return new Date(date.split('T')[0] + 'T12:00:00');
    // return  format(new Date(new Date(date).toISOString().split('T')[0]), 'yyyy-MM-dd');
};

const formatMetadataDate = (updatedAt: string | null | undefined, createdAt: string | null | undefined) => {
    if (updatedAt) {
        try {
            return `Last updated: ${format(new Date(updatedAt), 'MMM d, yyyy HH:mm:ss')}`;
        } catch (e) {
            return 'Invalid update date';
        }
    } else if (createdAt) {
        try {
            return `Created: ${format(new Date(createdAt), 'MMM d, yyyy HH:mm:ss')}`;
        } catch (e) {
            return 'Invalid creation date';
        }
    }
    return 'No date available';
};

const formatCreatorTooltip = (created_at: string) => {
    return `Created: ${format(new Date(created_at), 'MM/dd/yyyy HH:mm:ss')}`;
};

const formatUpdaterTooltip = (updated_at: string) => {
    return `Updated: ${format(new Date(updated_at), 'MM/dd/yyyy HH:mm:ss')}`;
};

// Add source options constant
const DATE_LIST_SOURCES = [
    { value: 'manual', label: 'Manual' },
    { value: 'generate', label: 'Generate' },
    { value: 'major_us_holidays', label: 'Major US Holidays' },
    { value: 'all_dates', label: 'All Dates' }
] as const;

// Add type for day of week config
interface DayOfWeek {
    key: string;
    label: string;
    shortLabel: string;
}

// Add days of week configuration
const daysOfWeek: DayOfWeek[] = [
    { key: 'sunday', label: 'Sunday', shortLabel: 'S' },
    { key: 'monday', label: 'Monday', shortLabel: 'M' },
    { key: 'tuesday', label: 'Tuesday', shortLabel: 'T' },
    { key: 'wednesday', label: 'Wednesday', shortLabel: 'W' },
    { key: 'thursday', label: 'Thursday', shortLabel: 'T' },
    { key: 'friday', label: 'Friday', shortLabel: 'F' },
    { key: 'saturday', label: 'Saturday', shortLabel: 'S' },
];

const isValidStayDateRange = (start: string | null, end: string | null) => {
    if (!start || !end) return true;
    return new Date(start) < new Date(end);
};

// Add helper function to format creator name
const formatCreatorName = (email: string) => {
    return email ? email.split('@')[0] : '-';
};

// Add helper function to format date ranges
const formatDateRange = (list: DateList): string => {
    // Check for stay dates first
    if (list.stay_date_start && list.stay_date_end) {
        return `${formatDate(list.stay_date_start)} to ${formatDate(list.stay_date_end)}`;
    }
    
    // Then check for days out
    if (list.days_out_start !== null && 
        list.days_out_end !== null && 
        (list.days_out_start > 0 || list.days_out_end > 0)) {
        return `${list.days_out_start} - ${list.days_out_end} days`;
    }
    
    // Default case
    return '-';
};

// Add date validation helper
const isValidDateFormat = (dateStr: string): boolean => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateStr)) return false;
    
    const date = new Date(dateStr);
    return date instanceof Date && !isNaN(date.getTime());
};

export const DateLists: React.FC<DateListsProps> = ({
    initialSelectedListId,
    currentUser,
    onClearSelection
}) => {
    const [sortBy, setSortBy] = useState<keyof DateList>('id');
    const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchQuery, setSearchQuery] = useState('');
    const [editingRows, setEditingRows] = useState<{ [key: number]: boolean }>({});
    const [editingStates, setEditingStates] = useState<{ [key: number]: Omit<DateList, 'id'> }>({});
    const [savingRows, setSavingRows] = useState<{ [key: number]: boolean }>({});
    const [nameErrors, setNameErrors] = useState<{ [key: number]: string }>({});
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [selectedDateList, setSelectedDateList] = useState<DateList | null>(null);
    const [dateIdsText, setDateIdsText] = useState('');
    const [uploadingDates, setUploadingDates] = useState<{ [key: number]: boolean }>({});
    const [linkDatesToParent] = useLinkDatesToParentMutation();
    const [downloadingRows, setDownloadingRows] = useState<{ [key: number]: boolean }>({});
    const [isAddingList, setIsAddingList] = useState(false);

    const { data: dateLists, isLoading, refetch, isFetching } = useGetAllDateListsQuery();
    const [createDateList] = useAddDateListMutation();
    const [updateDateList] = useUpdateDateListMutation();
    const [refreshDateList] = useRefreshDateListMutation();
    const [refreshingRows, setRefreshingRows] = useState<{ [key: number]: boolean }>({});

    const [getDateList] = useGetDateListMutation();

    const handleSort = (column: keyof DateList) => {
        if (sortBy === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortDirection('asc');
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleAddDateList = async () => {
        const newDateList: Omit<DateList, 'id'> = {
            name: 'New Date List',
            admin_only: false,
            description: 'Add a description',
            source: 'manual',
            stay_date_start: null,
            stay_date_end: null,
            days_out_start: null,
            days_out_end: null,
            sunday_checked: true,
            monday_checked: true,
            tuesday_checked: true,
            wednesday_checked: true,
            thursday_checked: true,
            friday_checked: true,
            saturday_checked: true,
        };

        try {
            setIsAddingList(true);
            setIsRefreshing(true);
            await createDateList(newDateList).unwrap();
            await refetch();
            setPage(0);
            setSortBy('id');
            setSortDirection('desc');
        } catch (error) {
            console.error('Failed to create date list:', error);
        } finally {
            setIsAddingList(false);
            setTimeout(() => {
                setIsRefreshing(false);
            }, 1000);
        }
    };

    const handleEdit = (dateList: DateList) => {
        if (dateList.admin_only && !currentUser?.admin) {
            console.warn('Only admins can edit admin-only lists');
            return;
        }
        
        setEditingRows(prev => ({
            ...prev,
            [dateList.id]: true
        }));

        setEditingStates(prev => ({
            ...prev,
            [dateList.id]: {
                name: dateList.name || '',
                description: dateList.description || '',
                admin_only: dateList.admin_only || false,
                source: dateList.source || 'manual',
                stay_date_start: dateList.stay_date_start || null,
                stay_date_end: dateList.stay_date_end || null,
                days_out_start: Number(dateList.days_out_start),
                days_out_end: Number(dateList.days_out_end),
                sunday_checked: dateList.sunday_checked || false,
                monday_checked: dateList.monday_checked || false,
                tuesday_checked: dateList.tuesday_checked || false,
                wednesday_checked: dateList.wednesday_checked || false,
                thursday_checked: dateList.thursday_checked || false,
                friday_checked: dateList.friday_checked || false,
                saturday_checked: dateList.saturday_checked || false,
            }
        }));
    };

    const isNameUnique = (name: string, currentId: number) => {
        return !dateLists?.some(list => 
            list.id !== currentId && 
            list.name.toLowerCase() === name.toLowerCase()
        );
    };

    const handleSave = async (list: DateList) => {
        if (!editingStates[list.id]?.name.trim()) {
            setNameErrors(prev => ({
                ...prev,
                [list.id]: 'Name is required'
            }));
            return;
        }

        if (!isNameUnique(editingStates[list.id].name, list.id)) {
            setNameErrors(prev => ({
                ...prev,
                [list.id]: 'Name must be unique'
            }));
            return;
        }

        // Add days of week validation for generate source
        if (editingStates[list.id].source === 'generate' && 
            !daysOfWeek.some(day => editingStates[list.id][`${day.key}_checked`])) {
            console.error('At least one day must be selected');
            return;
        }

        setSavingRows(prev => ({ ...prev, [list.id]: true }));

        try {
            const updatedList = {
                ...editingStates[list.id],
                id: list.id,
            };

            await updateDateList({ 
                id: list.id,
                data: updatedList 
            }).unwrap();

            if (initialSelectedListId === list.id) {
                onClearSelection?.();
            }

            setEditingRows(prev => {
                const newState = { ...prev };
                delete newState[list.id];
                return newState;
            });
            
            setEditingStates(prev => {
                const newState = { ...prev };
                delete newState[list.id];
                return newState;
            });

            setNameErrors(prev => {
                const newState = { ...prev };
                delete newState[list.id];
                return newState;
            });

            await refetch();
        } catch (error) {
            console.error('Failed to update date list:', error);
        } finally {
            setSavingRows(prev => {
                const newState = { ...prev };
                delete newState[list.id];
                return newState;
            });
        }
    };

    const handleCancel = (id: number) => {
        if (initialSelectedListId === id) {
            onClearSelection?.();
        }
        
        setEditingRows(prev => {
            const newState = { ...prev };
            delete newState[id];
            return newState;
        });
        
        setEditingStates(prev => {
            const newState = { ...prev };
            delete newState[id];
            return newState;
        });
    };

    const handleRefresh = async () => {
        setIsRefreshing(true);
        try {
            await refetch();
        } finally {
            setIsRefreshing(false);
        }
    };

    const handleRefreshRow = async (dateList: DateList) => {
        try {
            setRefreshingRows(prev => ({
                ...prev,
                [dateList.id]: true
            }));

            // Prepare the data with proper type handling
            const validatedDateList = {
                ...dateList,
                name: dateList.name || '',
                description: dateList.description || '',
                admin_only: dateList.admin_only || false,
                source: dateList.source || 'manual',
                stay_date_start: dateList.stay_date_start || null,
                stay_date_end: dateList.stay_date_end || null,
                days_out_start: Number(dateList.days_out_start),
                days_out_end: Number(dateList.days_out_end),
                sunday_checked: dateList.sunday_checked || false,
                monday_checked: dateList.monday_checked || false,
                tuesday_checked: dateList.tuesday_checked || false,
                wednesday_checked: dateList.wednesday_checked || false,
                thursday_checked: dateList.thursday_checked || false,
                friday_checked: dateList.friday_checked || false,
                saturday_checked: dateList.saturday_checked || false,
            };

            await refreshDateList({
                id: dateList.id, 
                data: validatedDateList
            });
            await refetch();
        } catch (error) {
            console.error('Failed to refresh date list:', error);
        } finally {
            setRefreshingRows(prev => ({
                ...prev,
                [dateList.id]: false
            }));
        }
    };

    const handleUploadClick = (dateList: DateList) => {
        setSelectedDateList(dateList);
        setUploadDialogOpen(true);
        setDateIdsText('');
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target?.result as string;
            setDateIdsText(text);
        };
        reader.readAsText(file);
    };

    const handleUploadSubmit = async () => {
        if (!selectedDateList) return;

        try {
            setUploadingDates(prev => ({
                ...prev,
                [selectedDateList.id]: true
            }));

            // Parse dates from text, handling both comma and newline separators
            const dates = dateIdsText
                .split(/[\n,]+/)
                .map(date => date.trim())
                .filter(date => date && isValidDateFormat(date));

            if (dates.length === 0) {
                console.error('No valid dates found. Please use YYYY-MM-DD format (e.g., 2025-01-31)');
                return;
            }

            await linkDatesToParent({
                id: selectedDateList.id,
                data: dates
            });

            setUploadDialogOpen(false);
            setDateIdsText('');
            await refetch();

        } catch (error) {
            console.error('Failed to upload dates:', error);
        } finally {
            setUploadingDates(prev => ({
                ...prev,
                [selectedDateList.id]: false
            }));
        }
    };

    const handleDownload = async (dateList: DateList) => {
        try {
            setDownloadingRows(prev => ({
                ...prev,
                [dateList.id]: true
            }));

            // Fetch the date list data
            const response = await getDateList(dateList.id as number).unwrap();
            // Create CSV content
            const csvContent = `Dates\n${response.join('\n')}`;

            // Create blob and download link
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);

            link.setAttribute('href', url);
            link.setAttribute('download', `date-list-${dateList.id}.csv`);
            link.style.visibility = 'hidden';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error('Failed to download date list:', error);
        } finally {
            setDownloadingRows(prev => ({
                ...prev,
                [dateList.id]: false
            }));
        }
    };

    // Add helper function to format days of week display
    const formatDaysOfWeek = (dateList: DateList) => {
        const days = [
            { key: 'sunday', label: 'S' },
            { key: 'monday', label: 'M' },
            { key: 'tuesday', label: 'T' },
            { key: 'wednesday', label: 'W' },
            { key: 'thursday', label: 'T' },
            { key: 'friday', label: 'F' },
            { key: 'saturday', label: 'S' }
        ];

        return (
            <div style={{ display: 'flex', gap: '2px' }}>
                {days.map(day => (
                    <div
                        key={day.key}
                        style={{
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                            backgroundColor: dateList[`${day.key}_checked`] ? '#1976d2' : '#e0e0e0',
                            color: dateList[`${day.key}_checked`] ? 'white' : '#757575',
                            fontSize: '12px'
                        }}
                    >
                        {day.label}
                    </div>
                ))}
            </div>
        );
    };

    const filteredAndSortedData = useMemo(() => {
        if (!dateLists) return [];

        return dateLists
            .filter(list =>
                list.id.toString().includes(searchQuery) ||
                list.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                list.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
                list.creator.toLowerCase().includes(searchQuery.toLowerCase()) ||
                list.updater.toLowerCase().includes(searchQuery.toLowerCase()) ||
                list.source.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .sort((a, b) => {
                const aValue = a[sortBy];
                const bValue = b[sortBy];

                if (sortDirection === 'asc') {
                    return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
                } else {
                    return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
                }
            });
    }, [dateLists, searchQuery, sortBy, sortDirection]);

    const paginatedData = useMemo(() => {
        const startIndex = page * rowsPerPage;
        return filteredAndSortedData.slice(startIndex, startIndex + rowsPerPage);
    }, [filteredAndSortedData, page, rowsPerPage]);

    // Add effect to handle initial selection
    useEffect(() => {
        if (initialSelectedListId) {
            const list = dateLists?.find(l => l.id === initialSelectedListId);
            if (list) {
                handleEdit(list);
                onClearSelection?.(); // Clear immediately after setting up the edit
            }
        }
    }, [initialSelectedListId, dateLists]); // Only run when these values change

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Paper className="date-lists" style={{ position: 'relative' }}>
                {(isLoading || isFetching) && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        zIndex: 1,
                    }}>
                        <CircularProgress />
                    </div>
                )}
                <Box style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                }}>
                    <TextField
                        label="Search Date Lists"
                        variant="outlined"
                        size="small"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{ width: '400px' }}
                        InputProps={{
                            endAdornment: searchQuery && (
                                <IconButton
                                    size="small"
                                    onClick={() => setSearchQuery('')}
                                    style={{ padding: 4 }}
                                >
                                    <Icon.X height={16} width={16} />
                                </IconButton>
                            )
                        }}
                    />
                    <Tooltip title="Add Unit List">
                        <IconButton
                            onClick={handleAddDateList}
                            size="small"
                            disabled={isFetching}
                        >
                            <Icon.Plus height={20} width={20}/>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={isFetching ? "Loading..." : "Refresh data"}>
                        <IconButton
                            onClick={() => refetch()}
                            disabled={isFetching}
                            size="small"
                        >
                            {isFetching ? (
                                <CircularProgress size={20} />
                            ) : (
                                <Icon.RefreshCCW height={20} width={20}/>
                            )}
                        </IconButton>
                    </Tooltip>
                </Box>
                <div style={{ position: 'relative' }}>
                    {(isLoading) && (
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                        }}>
                            <CircularProgress />
                        </div>
                    )}
                
                    <TableContainer style={{ height: '600px', overflow: 'auto' }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{minWidth: '45px'}}>
                                        <TableSortLabel
                                            active={sortBy === 'id'}
                                            direction={sortBy === 'id' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('id')}
                                        >
                                            ID
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell 
                                        className="sticky-header-cell"
                                        style={{ 
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            minWidth: '150px'
                                        }}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'name'}
                                            direction={sortBy === 'name' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('name')}
                                        >
                                            Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell  style={{minWidth: '68px'}}>
                                        <TableSortLabel
                                            active={sortBy === 'admin_only'}
                                            direction={sortBy === 'admin_only' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('admin_only')}
                                        >
                                            Admin
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{minWidth: '65px'}}>
                                        <TableSortLabel
                                            active={sortBy === 'date_count'}
                                            direction={sortBy === 'date_count' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('date_count')}
                                        >
                                            Count
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{minWidth: '100px'}}>Creator</TableCell>
                                    <TableCell  style={{minWidth: '90px'}}>
                                        <TableSortLabel
                                            active={sortBy === 'source'}
                                            direction={sortBy === 'source' ? sortDirection : 'asc'}
                                            onClick={() => handleSort('source')}
                                        >
                                            Source
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{minWidth: '90px'}}>Dates</TableCell>
                                    <TableCell style={{minWidth: '100px'}}>Days of Week</TableCell>
                                    <TableCell style={{minWidth: '100px'}}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedData.map((list) => (
                                    <TableRow key={list.id}>
                                        <TableCell>{list.id}</TableCell>
                                        {editingRows[list.id] ? (
                                            <>
                                                <TableCell colSpan={3}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                        <TextField
                                                            value={editingStates[list.id]?.name}
                                                            onChange={(e) => {
                                                                setEditingStates(prev => ({
                                                                    ...prev,
                                                                    [list.id]: {
                                                                        ...prev[list.id],
                                                                        name: e.target.value
                                                                    }
                                                                }));
                                                            }}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                            label="Name"
                                                            error={!!nameErrors[list.id]}
                                                            helperText={nameErrors[list.id]}
                                                        />
                                                        <TextField
                                                            value={editingStates[list.id]?.description}
                                                            onChange={(e) => {
                                                                setEditingStates(prev => ({
                                                                    ...prev,
                                                                    [list.id]: {
                                                                        ...prev[list.id],
                                                                        description: e.target.value
                                                                    }
                                                                }));
                                                            }}
                                                            size="small"
                                                            variant="outlined"
                                                            fullWidth
                                                            label="Description"
                                                        />
                                                        {currentUser?.admin && (
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={editingStates[list.id]?.admin_only}
                                                                        onChange={(e) => {
                                                                            setEditingStates(prev => ({
                                                                                ...prev,
                                                                                [list.id]: {
                                                                                    ...prev[list.id],
                                                                                    admin_only: e.target.checked
                                                                                }
                                                                            }));
                                                                        }}
                                                                    />
                                                                }
                                                                label="Admin Only"
                                                            />
                                                        )}
                                                    </div>
                                                </TableCell>
                                                <TableCell colSpan={4}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                        <FormControl fullWidth size="small">
                                                            <InputLabel>Source</InputLabel>
                                                            <Select
                                                                value={editingStates[list.id]?.source || 'manual'}
                                                                onChange={(e) => {
                                                                    setEditingStates(prev => ({
                                                                        ...prev,
                                                                        [list.id]: {
                                                                            ...prev[list.id],
                                                                            source: e.target.value as string
                                                                        }
                                                                    }));
                                                                }}
                                                                label="Source"
                                                            >
                                                                {DATE_LIST_SOURCES.map(source => (
                                                                    <MenuItem key={source.value} value={source.value}>
                                                                        {source.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>

                                                        {editingStates[list.id]?.source === 'generate' && (
                                                            <>
                                                                <div style={{ display: 'flex', gap: '8px' }}>
                                                                    <KeyboardDatePicker
                                                                        margin="none"
                                                                        label="Stay Date Start"
                                                                        format="yyyy-MM-dd"
                                                                        value={formatDate(editingStates[list.id].stay_date_start, true)}
                                                                        onChange={(date) => {
                                                                            if (!date) return;
                                                                            const formattedDate = format(date, 'yyyy-MM-dd');
                                                                            setEditingStates(prev => ({
                                                                                ...prev,
                                                                                [list.id]: {
                                                                                    ...prev[list.id],
                                                                                    stay_date_start: formattedDate
                                                                                }
                                                                            }));
                                                                        }}
                                                                        KeyboardButtonProps={{
                                                                            'aria-label': 'change stay date start',
                                                                        }}
                                                                        fullWidth
                                                                        size="small"
                                                                    />
                                                                    <KeyboardDatePicker
                                                                        margin="none"
                                                                        label="Stay Date End"
                                                                        format="yyyy-MM-dd"
                                                                        value={formatDate(editingStates[list.id].stay_date_end, true)}
                                                                        onChange={(date) => {
                                                                            if (!date) return;
                                                                            const formattedDate = format(date, 'yyyy-MM-dd');
                                                                            setEditingStates(prev => ({
                                                                                ...prev,
                                                                                [list.id]: {
                                                                                    ...prev[list.id],
                                                                                    stay_date_end: formattedDate
                                                                                }
                                                                            }));
                                                                        }}
                                                                        KeyboardButtonProps={{
                                                                            'aria-label': 'change stay date end',
                                                                        }}
                                                                        fullWidth
                                                                        size="small"
                                                                        minDate={formatDate(editingStates[list.id].stay_date_start, true)}
                                                                    />
                                                                </div>
                                                                <div style={{ display: 'flex', gap: '8px' }}>
                                                                    <TextField
                                                                        label="Days Out Start"
                                                                        type="number"
                                                                        value={editingStates[list.id]?.days_out_start ?? ''}
                                                                        onChange={(e) => {
                                                                            setEditingStates(prev => ({
                                                                                ...prev,
                                                                                [list.id]: {
                                                                                    ...prev[list.id],
                                                                                    days_out_start: e.target.value === '' ? null : Number(e.target.value)
                                                                                }
                                                                            }));
                                                                        }}
                                                                        size="small"
                                                                        fullWidth
                                                                    />
                                                                    <TextField
                                                                        label="Days Out End"
                                                                        type="number"
                                                                        value={editingStates[list.id]?.days_out_end ?? ''}
                                                                        onChange={(e) => {
                                                                            setEditingStates(prev => ({
                                                                                ...prev,
                                                                                [list.id]: {
                                                                                    ...prev[list.id],
                                                                                    days_out_end: e.target.value === '' ? null : Number(e.target.value)
                                                                                }
                                                                            }));
                                                                        }}
                                                                        size="small"
                                                                        fullWidth
                                                                    />
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={daysOfWeek.every(day =>
                                                                                    editingStates[list.id]?.[`${day.key}_checked`]
                                                                                )}
                                                                                onChange={(e) => {
                                                                                    const checked = e.target.checked;
                                                                                    setEditingStates(prev => ({
                                                                                        ...prev,
                                                                                        [list.id]: {
                                                                                            ...prev[list.id],
                                                                                            ...daysOfWeek.reduce((acc, day) => ({
                                                                                                ...acc,
                                                                                                [`${day.key}_checked`]: checked ||
                                                                                                    (day.key === 'sunday' && !checked)
                                                                                            }), {})
                                                                                        }
                                                                                    }));
                                                                                }}
                                                                            />
                                                                        }
                                                                        label="All Days"
                                                                    />
                                                                    <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
                                                                        {daysOfWeek.map(day => (
                                                                            <div key={day.key} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px' }}>
                                                                                <span style={{ fontSize: '12px' }}>{day.shortLabel}</span>
                                                                                <Checkbox
                                                                                    checked={editingStates[list.id]?.[`${day.key}_checked`]}
                                                                                    onChange={(e) => {
                                                                                        if (!e.target.checked &&
                                                                                            daysOfWeek.every(d =>
                                                                                                d.key === day.key || !editingStates[list.id]?.[`${d.key}_checked`]
                                                                                            )) {
                                                                                            return;
                                                                                        }

                                                                                        setEditingStates(prev => ({
                                                                                            ...prev,
                                                                                            [list.id]: {
                                                                                                ...prev[list.id],
                                                                                                [`${day.key}_checked`]: e.target.checked
                                                                                            }
                                                                                        }));
                                                                                    }}
                                                                                    size="small"
                                                                                />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div style={{ display: 'flex'}}>
                                                        <Tooltip title="Save">
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleSave(list)}
                                                                disabled={savingRows[list.id]}
                                                            >
                                                                {savingRows[list.id] ? (
                                                                    <CircularProgress size={16} />
                                                                ) : (
                                                                    <Icon.Check height={16} width={16} />
                                                                )}
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Cancel">
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleCancel(list.id)}
                                                                disabled={savingRows[list.id]}
                                                            >
                                                                <Icon.X height={16} width={16} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </TableCell>
                                            </>
                                        ) : (
                                            <>
                                                <TableCell 
                                                    style={{ 
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        maxWidth: '200px'  // Adjust this value based on your needs
                                                    }}
                                                >
                                                    <Tooltip title={list.description || 'No description'}>
                                                        <span>{list.name}</span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>
                                                    <Chip
                                                        label={list.admin_only ? 'Yes' : 'No'}
                                                        size="small"
                                                        color={list.admin_only ? 'primary' : 'default'}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {list.date_count || 0}
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title={formatCreatorTooltip(list.created_at)}>
                                                        <span>{formatCreatorName(list.creator)}</span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>
                                                    {DATE_LIST_SOURCES.find(s => s.value === list.source)?.label || list.source}
                                                </TableCell>
                                                <TableCell>
                                                    {formatDateRange(list)}
                                                </TableCell>
                                                <TableCell>
                                                    {formatDaysOfWeek(list)}
                                                </TableCell>
                                                <TableCell>
                                                    <div style={{ display: 'flex' }}>
                                                        {editingRows[list.id] ? (
                                                            <>
                                                                <Tooltip title={nameErrors[list.id] || "Save"}>
                                                                    <span>
                                                                      <IconButton
                                                                          size="small"
                                                                          onClick={() => handleSave(list)}
                                                                          disabled={
                                                                              savingRows[list.id] ||
                                                                              isFetching ||
                                                                              !!nameErrors[list.id] ||
                                                                              !editingStates[list.id]?.name.trim()
                                                                          }
                                                                      >
                                                                        {savingRows[list.id] ? (
                                                                            <CircularProgress size={16} />
                                                                        ) : (
                                                                            <Icon.Check height={16} width={16} />
                                                                        )}
                                                                      </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                                <Tooltip title="Cancel">
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() => handleCancel(list.id)}
                                                                        disabled={savingRows[list.id] || isFetching}
                                                                    >
                                                                        <Icon.X height={16} width={16} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Tooltip title={
                                                                    list.admin_only && !currentUser?.admin 
                                                                        ? "Only admins can edit admin-only lists" 
                                                                        : "Edit"
                                                                }>
                                                                    <span>
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() => handleEdit(list)}
                                                                            disabled={
                                                                                isFetching || 
                                                                                refreshingRows[list.id] || 
                                                                                (list.admin_only && !currentUser?.admin)
                                                                            }
                                                                        >
                                                                            {isFetching ? (
                                                                                <CircularProgress size={16} />
                                                                            ) : (
                                                                                <Icon.Edit2 height={16} width={16} />
                                                                            )}
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                                <Tooltip title={
                                                                    list.admin_only && !currentUser?.admin 
                                                                        ? "Only admins can upload to admin-only lists" 
                                                                        : "Upload Dates"
                                                                }>
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() => handleUploadClick(list)}
                                                                        disabled={
                                                                            isFetching || 
                                                                            uploadingDates[list.id] || 
                                                                            (list.admin_only && !currentUser?.admin)
                                                                        }
                                                                    >
                                                                        {uploadingDates[list.id] ? (
                                                                            <CircularProgress size={16} />
                                                                        ) : (
                                                                            <Icon.Upload height={16} width={16} />
                                                                        )}
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Download Dates">
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() => handleDownload(list)}
                                                                        disabled={isFetching || downloadingRows[list.id]}
                                                                    >
                                                                        {downloadingRows[list.id] ? (
                                                                            <CircularProgress size={16} />
                                                                        ) : (
                                                                            <Icon.Download height={16} width={16} />
                                                                        )}
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title={
                                                                    list.admin_only && !currentUser?.admin 
                                                                        ? "Only admins can refresh admin-only lists" 
                                                                        : formatMetadataDate(list.updated_at, list.created_at)
                                                                }>
                                                                    <span>
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() => handleRefreshRow(list)}
                                                                            disabled={
                                                                                isFetching || 
                                                                                refreshingRows[list.id] || 
                                                                                (list.admin_only && !currentUser?.admin)
                                                                            }
                                                                        >
                                                                            {refreshingRows[list.id] ? (
                                                                                <CircularProgress size={16} />
                                                                            ) : (
                                                                                <Icon.RefreshCCW height={16} width={16} />
                                                                            )}
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={filteredAndSortedData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <Dialog
                    open={uploadDialogOpen}
                    onClose={() => setUploadDialogOpen(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>Upload Dates</DialogTitle>
                    <DialogContent>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                            <Input
                                type="file"
                                // accept=".csv,.txt"
                                onChange={handleFileUpload}
                                style={{ marginBottom: '16px' }}
                            />
                            <Typography variant="body2" color="textSecondary">
                                Or paste dates below (comma or newline separated):
                            </Typography>
                            <TextareaAutosize
                                rowsMin={5}
                                value={dateIdsText}
                                onChange={(e) => setDateIdsText(e.target.value)}
                                style={{ width: '100%', padding: '8px' }}
                                placeholder="Enter dates in YYYY-MM-DD format (e.g., 2025-01-31)"
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setUploadDialogOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleUploadSubmit}
                            color="primary"
                            disabled={!dateIdsText.trim()}
                        >
                            Upload
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </MuiPickersUtilsProvider>
    );
};

export default DateLists; 